.chat-room-container {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    
    font-family: 'Nunito-Bold';
    background: '#F4F4F4'
}

.room-name {
    margin-top: 0;
    font-family: 'Nunito-Bold'
}

.messages-container {
    flex: 1;
    min-height: 100px;
    overflow: auto;
    background-color: #F4F4F4;
    font-family: 'Nunito-Bold';
}

.messages-list {
    list-style-type: none;
    padding: 0;
    font-family: 'Nunito-Bold'
}

.new-message-input-field {
    height: 50px;
    font-size: 14px;
    resize: none;
    font-family: 'Nunito-Bold';
    flex: 1;
    margin-right: 8px;
    padding-left: 8px;
    padding-top: 16px;
    box-sizing: border-box;
}

.message-item {
    width: 55%;
    margin: 8px;
    padding: 12px 8px;
    word-break: break-word;
    border-radius: 4px;
    color: white;
    font-family: 'Nunito-Bold'
}

.my-message {
    background-color: rgb(53 150 241);
    margin-left: auto;
    font-family: 'Nunito-Bold';
    border-radius: 20px 20px 4px 20px;
    padding-left: 10px;
    width: max-content;
    max-width: 50%;
}

.received-message {
    background-color: #44474c;
    margin-right: auto;
    font-family: 'Nunito-Bold';
    border-radius: 20px 20px 20px 4px;
}

.send-message-button {
    font-size: 28px;
    font-weight: 600;
    color: white;
    background: #F56B57;
    /* padding: 24px 12px; */
    border-width: 0px;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    line-height: 27px;
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Nunito-Bold';
}

.messages-container, .send-message-button {
    border-color: #9a9a9a;
}

input[type=text]:focus {
  border: 2px solid #3863F0;
  border-radius: 8px;
}

.message-item-text {
    word-break: break-word;
    white-space: pre-wrap;
}

a:link {
    color:#38ffa9;
}

a:hover {
    color: #ffee00;
}

a:active {
    color: #d9feff;
}

a:visited {
    color: #4b93ff;
}

.input-container {
  display: flex;
  
  flex-direction: row;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 16px;
  padding-right: 16px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);

}

.chat-bubble {
    background-color:#44474c;
    padding:16px 28px;
    -webkit-border-radius: 20px;
    -webkit-border-bottom-left-radius: 2px;
    -moz-border-radius: 20px;
    -moz-border-radius-bottomleft: 2px;
    border-radius: 20px;
    border-bottom-left-radius: 2px;
    margin-left: 10px;
    display:inline-block;
  }
  .typing {
    align-items: center;
    display: flex;
    height: 17px;
  }
  .typing .dot {
    animation: mercuryTypingAnimation 1.8s infinite ease-in-out;
    background-color: white ;
    border-radius: 50%;
    height: 7px;
    margin-right: 4px;
    vertical-align: middle;
    width: 7px;
    display: inline-block;
  }
  .typing .dot:nth-child(1) {
    animation-delay: 200ms;
  }
  .typing .dot:nth-child(2) {
    animation-delay: 300ms;
  }
  .typing .dot:nth-child(3) {
    animation-delay: 400ms;
  }
  .typing .dot:last-child {
    margin-right: 0;
  }

  .custom-link {
    color: #3863F0;
    text-decoration: none;
  }

  .custom-link:hover {
    color: #3863F0;
    text-decoration: underline;
  }
  
  @keyframes mercuryTypingAnimation {
    0% {
      transform: translateY(0px);
      background-color:#6CAD96; 
    }
    28% {
      transform: translateY(-7px);
      background-color:#9ECAB9; 
    }
    44% {
      transform: translateY(0px);
      background-color: #B5D9CB;
    }
  }
.home-container {
    position: fixed;
    left: 10%;
    right: 10%;
    top: 50%;
    transform: translate(0, -50%);
    display: flex;
    flex-direction: column;
}

.text-input-field {
    padding: 24px 12px;
    border-radius: 7px;
    font-size: 24px;
}

.text-input-field:focus {
    outline: none;
}

.enter-room-button {
    margin-top: 20px;
    padding: 24px 12px;
    font-size: 28px;
    background: #31a24c;
    color: white;
    font-weight: 600;
    text-align: center;
    text-decoration: none;
    border-radius: 7px;
}


body {
  margin: 0;
}

body, input, button, a, textarea {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

@font-face {
  font-family: "Nunito-Bold";
  src: local("Nunito-Bold"),
   url("./fonts/Nunito/Nunito-Bold.ttf") format("truetype");
  font-weight: bold;
 }